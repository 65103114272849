.footer {
    margin-top: 90px;
    margin-bottom: 287px;
}

.footer div.columns-container {
    display: flex;
    justify-content: space-between;
}

.footer .footer-title {
    margin-bottom: 24px;
    margin-top: 0;
}

.footer .email-send {
    padding: 11.25px 22px;
    width: 100%;
}
.footer .email-send {
    outline: none !important;
}

.footer .email-input.error .email-send {
    border: 0.5px solid #DA0000;
    background: #FF9D9D;
    color: #DA0000;
}
.footer .email-input.success .email-send {
    border: 0.5px solid #46DA00;
    background: #CCFFB5;
    color: #46DA00;
}
.footer .email-input .email-send {
    transition: .3s;
    border: 0.5px solid #3A3A3A;
    font-size: 16px;
}
.footer .email-input {
    position: relative;
    z-index: 0;
}

.footer .email-input .icon-img {
    position: absolute;
    right: 21px;
    top: 17px;
}
.footer .email-input svg.icon-img {
    top: 8px;
}

.footer .homeBtn {
    margin-bottom: 36px;
    display: inline-block;
}

/*.footer .homeBtn img {*/
    /*filter: invert(1);*/
/*}*/

.email-input-error {
    position: absolute;
    color: red;
}

@media(max-width: 768px) {
    .footer div.columns-container {
        margin-bottom: calc(100vh - 350px);
        flex-wrap: wrap;
        align-items: flex-end;
    }


    .footer .homeBtn {
        margin-bottom: 16px;
    }
    .footer .footer-title {
        margin-bottom: 12px;
        padding-right: 14%;
    }

    .footer .footer-logo {
        margin-bottom: 42px;
        width: 100%;
    }

    .footer .footer-links {
        margin-bottom: 16px;
    }

    .footer p, .footer a {
        font-size: 16px;
    }
}
