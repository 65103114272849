.content__container {
    padding: 15px;
    height: calc(100vh - 57px);
    width: 100%;
    background-color: #dcdcdc;
    overflow-y: scroll;
}

.admin__title {
    margin-bottom: 10px;
}
