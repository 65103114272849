.cart-total {
    width: 30.5%;
    padding-top: 4px;
}

.cart-total .cart-total__subtotal,
.cart-total .cart-total__total {
    margin-bottom: 68px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}
.cart-total .cart-total__subtotal span,
.cart-total .cart-total__total span {
    /*min-width: 100px;*/
}

.cart-total .cart-total__check-out{
    width: 100%;
    height: 32px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: #fff;
    position: relative;
}
.cart-total .cart-total__check-out .deliveryHint {
    position: absolute;
    top: -30px;
    color: #606060;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 769px) {
    .cart-total {
        width: 100%;
    }
    .cart-total .cart-total__subtotal {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin-bottom: 24px;
        margin-top: 0;
    }
    .cart-total .cart-total__total {
        font-size: 10px;
        font-weight: 400;
        color: #606060;
        text-align: center;
        display: block;
        margin-top: 0;
        margin-bottom: 16px;
        opacity: 0;
    }
    .cart-total .cart-total__check-out{
        background-color: black;
        color: white;
    }
}
