.cart-wrapper {
    min-height: calc(100vh - (46px * 2) - 37px);
}

.cart-container {
    display: flex;
    justify-content: space-between;
}

.empty-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - (46px * 2) - 37px);
}

.empty-cart .client__title {
    margin-bottom: 16px;
}
.empty-cart .cart-description {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 60px;
}
.empty-cart .cart-button__continue-shopping {
    height: 32px;
    padding: 6px 15px;
    border: 1px solid;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

@media( max-width: 769px ) {
    .cart-container {
        flex-direction: column;
        justify-content: flex-start;
    }
}
