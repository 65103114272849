* {
  box-sizing: border-box;
}

button {
  border: none;
  background: transparent;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}
body {
  padding: 46px;
  margin: 0;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  color: black;
  overflow-x: hidden;
}

body.no__moves-buddy {
  overflow: hidden;
}

body.max1279 {
  max-width: 1279px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  body {
    padding: 20px;
  }
}

a, a:active, a:visited {
  text-decoration: none;
  color: black;
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.client__title {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 60px;
}

.thank-you-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70vh;
}
