.custom-input textarea {
    padding: 10px;
    border-radius: 10px;
}

.custom-input .add__images__block {
    position: relative;
}
.custom-input .add__images__block input,
.custom-input .add__images__block input::-webkit-file-upload-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 0;
    cursor: pointer;
}

.custom-input .add__images__block .add__images__button {
    pointer-events: none;
    margin-bottom: 10px;
}
.custom-input .add__images__block .drag__drop {
    border: 2px dashed #aaa;
    padding: 100px;
    text-align: center;
    cursor: pointer;
}

.custom-input .images__container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.custom-input .images__container .images-item {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    display: flex;
    min-height: 55px;
}
.custom-input .images__container .images-item img {
    border-radius: 10px;
}
.custom-input .images__container .images-item .remove__image {
    background-color: #f53d3d;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.custom-input .images__container .images-item .remove__image:hover {
    background-color: #f84848;
}

.custom-input.error {
    color: #f53d3d;
}
.custom-input.error .drag__drop {
    border-color: #f53d3d;
}
.custom-input.error .MuiButtonBase-root {
    background-color: #f53d3d;
}
.custom-input.error .color__block.add-text,
.custom-input.error label {
    color: red;
}

.custom-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

