.cart__pop-up {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    max-width: 390px;
    transition: .3s;
    background: rgba(0, 0, 0, 0.70);
    opacity: 0;
    padding: 24px 24px 46px 24px;
    pointer-events: none;
    z-index: 1;
}

.cart__pop-up.active {
    opacity: 1;
    pointer-events: initial;
}

.cart__pop-up .cross {
    display: block;
    margin-left: auto;
    filter: invert(1);
    margin-bottom: -21px;
    cursor: pointer;
}

.cart__pop-up h3 {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 24px;
}
.cart__pop-up .popup-image__container {
    width: 100%;
    aspect-ratio: 1 / 1;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}
.cart__pop-up .popup-image {
    position: relative;
}
.cart__pop-up .popup-image.swanpendantpng {
    top: 20%;
}
.cart__pop-up .popup-image.set3jpg {
    top: -2%;
}
.cart__pop-up .popup-image.set6jpg {
    top: -7%;
    transform: scale(0.9);
}
.cart__pop-up .popup-image.set7jpg {
    top: -3%;
    transform: scale(0.85);
}
.cart__pop-up .popup-image.set8jpg {
    transform: scale(0.75);
}
.cart__pop-up .popup-image.set9jpg {
    top: 15px;
}

.cart__pop-up .popup-image {
    max-width: 100%;
}
.cart__pop-up .slick-slider.main-slider.slick-initialized {
    margin-bottom: 0;
}
.cart__pop-up .popup-slider {
    margin-bottom: 24px;
}
.cart__pop-up .popup-quantity__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
}
.cart__pop-up .seashell-title {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    margin-top: 24px;
}
.cart__pop-up .popup-product__quantity-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 73px;
}
.cart__pop-up .popup-quantity__container,
.cart__pop-up .popup-product__quantity-label button {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.cart__pop-up .popup-product__quantity-label button {
    cursor: pointer;
}

.cart__pop-up .popup__add-to-card {
    height: 32px;
    border: 1px solid #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    position: relative;
}

.cart__pop-up .popup__add-to-card svg {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: .5s;
}
.cart__pop-up .popup__add-to-card svg path {
    fill: white;
}
.cart__pop-up .popup__add-to-card span {
    color: #fff;
    transition: .5s opacity;
}
.cart__pop-up .popup__add-to-card.adding-animation {
    color: #fff;
}
.cart__pop-up .popup__add-to-card.adding-animation span {
    opacity: 0;
}
.cart__pop-up .popup__add-to-card.adding-animation svg {
    opacity: 1;
}

.cart__pop-up .custom-radio .custom-radio__switch {
    border-color: white;
}
.cart__pop-up .custom-radio .custom-radio__value {
    color: #fff;
}
.cart__pop-up .custom-radio.checked .custom-radio__switch::before {
    background-color: white;
}

@media (max-width: 769px) {
    .cart__pop-up {
        height: 100%;
        bottom: 0;
        min-width: 100vw;
    }
    .cart__pop-up .dots-slider {
        top: -28px;
    }
}
