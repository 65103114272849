.info-page-wrapper {
    min-height: calc(100vh - (46px * 2) - 37px);
}

.info-page-wrapper ol,
.info-page-wrapper ul {
    padding-left: 25px;
}

.info-page-wrapper ol li {
    list-style-type: decimal;
    padding-left: 10px;
    font-size: 16px;
    color: #000;
}
.info-page-wrapper ul li {
    list-style-type: disc;
    font-size: 16px;
    color: #000;
    padding-left: 10px;
    margin-top: 0;
    margin-bottom: 16px;
}
.info-page-wrapper p, .info-page-wrapper ul, .info-page-wrapper ol {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 16px;
}

.info-page-wrapper h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.info-page-wrapper .publicOffer-ul {
    padding: 0;
}

.info-page-wrapper .publicOffer-ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

@media (max-width: 767px) {
    .info-page-wrapper h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 16px;
    }
    .info-page-wrapper ol li,
    .info-page-wrapper ul li {
        padding-left: 0;
        margin-bottom: 16px;
    }

    .info-page-wrapper p,
    .info-page-wrapper ol li,
    .info-page-wrapper ul li {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .info-page-wrapper strong {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .info-page-wrapper ol, .info-page-wrapper ul {
        padding-left: 19px;
    }
}
