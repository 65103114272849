.list__container {
    width: 100%;
    padding: 0 10px 10px;
    background: #ededed;
    border-radius: 11px;
}

.list__container h5 {
    font-size: 0;
}
.list__container.flex h5 {
    font-size: initial;
}
.list__container.flex {
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
}

.list__container .search {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid;
    padding: 10px 5px 10px 30px;
    outline: none;
}

.list__container .search__label .bi.bi-search {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
}

.list__container .search__label {
    position: relative;
    padding: 12px 15px;
    background-color: #cccccc;
    width: calc(100% + 20px);
    margin-left: -10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
}

.list__container .list-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    margin: 10px 0;
}

.list__container .list-items {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.list__container .list-items .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: black;
    color: white;
    padding: 10px 5px;
    border-radius: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
    transition: background-color .3s;
}
.list__container .list-items .list-item.hidden {
    background-color: darkgray;
}
.list__container .list-items .list-item.hidden:hover {
    background-color: gray;
}

.list__container .list-items .list-item.active {
    background-color: goldenrod;
}
.list__container .list-items .list-item.active:hover {
    background-color: #eab328;
}

.list__container .list-items .list-item:hover {
    background-color: #1e1e1e;
}

.list__container .list-item > *,
.list__container .list-titles > * {
    width: 16.6%;
    text-align: center;
}

.list-item .inUse span {
    cursor: context-menu;
    color: #fff;
}
.list-item img {
    height: 100px;
    width: auto;
}

.list-item .actions {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.list-item .actions button {
    background-color: white;
    color: black;
    padding: 3px;
    border-radius: 3px;
    transition: .3s;
}

.actions button.edit {
    min-width: 50px;
}

.actions button.delete {
    background-color: #f53d3d;
}

.actions button.edit:hover {
    background-color: #d2d2d2;
}

.actions button.delete:hover {
    background-color: #c93232;
}

.input__container {
    display: flex;
    gap: 10px;
    width: 100%;
}

.input__container .custom-input {
    width: calc(50% - 5px);
}

.input__container .custom-input input {
    width: 100%;
    padding: 13px;
}

.images__edit {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
    margin-bottom: 20px;
    gap: 20px;
}
.images__edit .images__edit-item {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    background-color: #a4a4a4;
}
.images__edit .images__edit-item img {
    width: 200px;
    height: auto;
}
.images__edit .images__edit-item .actions__images {
    display: flex;
    justify-content: space-between;
}
/*.images__edit .delete {*/
.images__edit .delete {
    background-color: red;
    color: #fff;
    height: 36.5px;
}
.images__edit .images__edit-item.add {
    min-height: 324px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.images__edit .images__edit-item .delete {
    aspect-ratio: 1 / 1;
}
.images__edit .images__edit-item .delete:hover {
    background-color: #ee0000;
}
.images__edit .images__row {
    padding: 8px;
    border-radius: 5px;
    background-color: #a4a4a4;
    margin-bottom: 10px;
}
.images__edit .images__row .general__actions {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 5px;
    background-color: #444444;
    padding: 5px;
    border-radius: 5px;
}

.product__edit {
    position: relative;
}
.product__edit .delete__product {
    position: absolute;
    right: 0;
    top: -40px;
    background-color: transparent;
    color: #000;
}

.product__edit .delete__product:hover {
    background-color: red;
    color: #fff;
}

.product__edit .edit__item {
    margin-bottom: 10px;
}
.product__edit .edit__item textarea {
    min-height: 120px;
}
.product__edit .edit__item input {
    min-width: 300px;
}

.inShow svg {
    fill: burlywood;
}

@media (max-width: 768px) {
    .product__edit .delete__product {
        top: initial;
        background-color: red;
        color: #fff;
        bottom: -90px;
    }
    .list__container .list-items,
    .list__container .list-titles {
        min-width: 858px;
    }
}
